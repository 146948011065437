import React, { useState, useEffect } from 'react';
import styles from './Basket.module.css';
import Layout from '../../components/Layout/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { removeItemAsync, populateBasketAsync } from '../../features/basket/basketSlice';
import QuantitySelector from '../../components/Product/QuantitySelector';
import { Icon } from '@iconify/react';
import { useNavigate, Link } from 'react-router-dom';
import { showLoading, hideLoading } from '../../features/ui/uiSlice';

const OfferPopup = ({ product, show, position, onClose }) => {
    if (!show) return null;

    return (
        <div
            className={styles.offerDetails}
            style={{
                position: 'absolute',
                top: `${position.top}px`,
                left: `${position.left}px`,
                zIndex: 1000,
                backgroundColor: 'white',
                border: '1px solid #ddd',
                borderRadius: '4px',
                padding: '10px',
                boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                minWidth: '200px',
                maxWidth: '300px'
            }}
        >
            {
                product.offer_type !== 'flat' ? (
                    product.prag_oferta.map((oferta, ofertaIndex) => (
                        product.offer_type === 'cumpara_x_primesti_y_procentaj' ? (
                            <div key={ofertaIndex} style={{ padding: '10px 0px' }}>
                                La {oferta.prag_cantitate} bucati cumparate, primiti {oferta.valoare_oferta}% reducere.
                            </div>
                        ) : product.offer_type === 'cumpara_x_primesti_y' ? (
                            <div key={ofertaIndex} style={{ padding: '10px 0px' }}>
                                La {oferta.prag_cantitate} bucati cumparate, primiti {oferta.valoare_oferta} bucati gratis.
                            </div>
                        ) : null
                    ))
                ) : (
                    <div>
                        Discount Liniar de {product.discount_percentage}%
                    </div>
                )
            }
        </div>
    );
};

const Basket = () => {
    const appUrl = process.env.REACT_APP_URL;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const productsObject = useSelector(state => state.basket.items);
    const { total_plata, total_suma_salvata } = useSelector(state => state.basket);
    const productsNumber = Object.keys(productsObject).length;
    const [selectedPoint, setSelectedPoint] = useState('');
    const [activeOfferId, setActiveOfferId] = useState(null);
    const [offerPosition, setOfferPosition] = useState({ top: 0, left: 0 });
    // Convert JSON object to array
    const products = Object.keys(productsObject).map(key => productsObject[key]);

    useEffect(() => {
        dispatch(showLoading());
        dispatch(populateBasketAsync(dispatch))
            .finally(() => {
                dispatch(hideLoading());
            })
    }, [dispatch]);

    const handleSelectedPoint = (option) => {
        setSelectedPoint(option);
    };

    const handleRemoveItem = (productId) => {
        dispatch(removeItemAsync({ productId }));
    };

    const handleNextStep = () => {
        if (!selectedPoint) {
            return; // No navigation, just prevent the action
        }
        navigate('/checkout', { state: { 'punct_de_lucru': selectedPoint } });
    };

    const handleToggleOffer = (productId, event) => {
        event.stopPropagation();
        if (activeOfferId === productId) {
            setActiveOfferId(null);
        } else {
            const rect = event.currentTarget.getBoundingClientRect();
            setOfferPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX
            });
            setActiveOfferId(productId);
        }
    };

    const handleCloseOffer = () => {
        setActiveOfferId(null);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (activeOfferId && !event.target.closest(`[data-offer-icon="${activeOfferId}"]`)) {
                setActiveOfferId(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [activeOfferId]);

    return (
        <Layout>
            {Object.keys(productsObject).length === 0 ? (
                <div className={styles.emptyBasketContainer}>
                    <div className={styles.cartImage}>
                        <img src="/misc/sad-cart.svg" alt="Logo" className={styles.headerImage} />
                    </div>
                    <div className={styles.individualEmptyBasket}>Cosul este gol.</div>
                    <div className={styles.individualEmptyBasket}>Cautati sau rasfoiti produse si adaugati-le in cos.</div>
                    <Link to="/">
                        <button className={styles.continueButton}>Continua cumparaturile</button>
                    </Link>
                </div>
            ) : (
                <div style={{width:'100%'}}>
                    <div style={{ paddingTop: '4rem' }}>Cos ({productsNumber}) </div>
                    <div className={styles.basketUpperContainer}>
                        <div className={styles.basketLeftContent}>
                            <div className={styles.basketContainer}>
                                {products?.map((product, index) => (
                                    <div className={`${styles.basketRow}`} key={index}>
                                        <div className={`${styles.basketColumn}`}>
                                            <img src={`${appUrl}${product.image}`} alt={product.productTitle} className={styles.basketProductImage} />
                                        </div>
                                        {
                                            product.offer_type !== 'none' ? <div className={styles.banderoleContainer}>
                                                <div className={styles.banderole}>
                                                    Oferta %
                                                </div>
                                            </div> : null
                                        }

                                        <div className={`${styles.basketColumn} ${styles.doubleColumn}`}>
                                            <Link to={`/produs/${product.id}`}>
                                                <div className={styles.basketName}>
                                                    {product.name}{ },  {product.product_retail?.toUpperCase()}
                                                </div>
                                            </Link >
                                            <div className={`${styles.insideDoubleColumn}`}>
                                                <div className={`${styles.basketD}`}>Cod produs: {product.product_code}</div>
                                                {product.unit_price &&
                                                    <div className={`${styles.basketD}`}>Pret pe unitate: € {product.unit_price}</div>
                                                }
                                                <div className={`${styles.basketD}`}>TVA: {product.tva}%</div>
                                            </div>
                                            {product.offer_type !== 'none' && (
                                                <div 
                                                    className={styles.iconWrapper} 
                                                    onClick={(e) => handleToggleOffer(product.id, e)}
                                                    data-offer-icon={product.id}
                                                >
                                                    <Icon
                                                        className={styles.rotatableIcon}
                                                        icon="bxs:offer"
                                                        color='#C52E3F'
                                                        inline={true}
                                                        height='30px'
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    <span style={{ marginLeft: '5px' }}>Click pentru oferta</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className={`${styles.basketColumn}, ${styles.quantColumn}`}>
                                            <QuantitySelector productId={product.id} context="basket" />
                                            {
                                                product.free_units>0?
                                                <div style={{alignSelf:'center', marginTop:'8px'}}>{product.free_units} buc gratis</div>
                                                :
                                                null
                                            }
                                        </div>
                                        <div className={`${styles.basketColumn} ${styles.basketTotalPrice}`}>
                                            {product.economy_amount ? (
                                                <>
                                                    <span className={styles.originalPrice}>€ {product.price}</span>
                                                    <span className={styles.currentPrice}>
                                                        € {product.price_with_offer}
                                                    </span>
                                                </>
                                            ) : (
                                                <span className={styles.currentPrice}>€ {product.price}</span>
                                            )}
                                        </div>
                                        <button className={`${styles.removeItemBasket} ${styles.rotatableIconRemove}`} onClick={() => handleRemoveItem(product.id)}>
                                            <Icon icon="fluent:delete-24-regular" color='black' inline={true} height='20px' />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.basketRightContent}>
                            <div>
                                <DropdownSection handleOption={handleSelectedPoint} />
                                <div className={styles.orderTotal}>Total: € {total_plata}</div>
                                {total_suma_salvata > 0 && (
                                    <div className={styles.orderTotal}>Ai economisit € {total_suma_salvata}</div>
                                )}
                            </div>
                            <button
                                className={`${styles.placeOrder} ${!selectedPoint ? styles.disabledButton : ''}`}
                                onClick={handleNextStep}
                                disabled={!selectedPoint}
                            >
                                Continua
                            </button>
                        </div>
                    </div>
                    {products.map((product) => (
                        <OfferPopup
                            key={product.id}
                            product={product}
                            show={activeOfferId === product.id}
                            position={offerPosition}
                            onClose={handleCloseOffer}
                        />
                    ))}
                </div>
            )}
        </Layout>
    );
}

const DropdownSection = ({ handleOption }) => {
    const userDetails = useSelector(state => state.user.userDetails);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        handleOption(option);
    };

    return (
        <div className={styles.dropdown}>
            <button onClick={handleToggle} className={styles.dropdownToggle}>
                {selectedOption ? selectedOption.denumire : 'Selectati punctul de lucru'}
                <div className={`${styles.arrow} ${isOpen ? styles.open : ''}`}></div>
            </button>
            {isOpen && (
                <ul className={styles.dropdownMenu}>
                    {userDetails.puncte_de_lucru.map((option) => (
                        <li
                            key={option?.id}
                            onClick={() => handleOptionClick(option)}
                            className={styles.dropdownMenuItem}
                        >
                            {option?.denumire}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Basket;