import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addItemAsync } from '../../features/basket/basketSlice';
import styles from './Product.module.css';
import QuantitySelector from './QuantitySelector';
import { setShowComponent } from '../../features/ui/uiSlice';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { showLoading, hideLoading } from '../../features/ui/uiSlice';
import AddedToCartModal from '../Modal/AddedToCartModal'

const Product = ({ product }) => {
    const [quantity, setQuantity] = useState(1);
    const dispatch = useDispatch();
    const appUrl = process.env.REACT_APP_URL;
    const [showAddedToCart, setShowAddedToCart] = useState(false);
    const userCountry = useSelector(state => state.user.userDetails.tara);

    const [offerType, setOfferType] = useState('');

    const { price, priceWithoutVAT } = useMemo(() => {
        const priceObj = product?.lista_preturi.find(item => item.tara === userCountry);
        const price = priceObj ? priceObj.pret : 0;
        const priceWithoutVAT = price / (1 + (product?.tva / 100));
        return { price, priceWithoutVAT };
    }, [product, userCountry]);

    const handleAddToBasket = () => {
        if (quantity > 0) {
            dispatch(showLoading());
            dispatch(addItemAsync({ productId: product.id, quantity }))
                .then((action) => {
                    if (addItemAsync.fulfilled.match(action)) {
                        setQuantity(1);
                        dispatch(setShowComponent(true));
                    }
                })
                .catch((error) => {
                    console.error('Unexpected error:', error);
                })
                .finally(() => {
                    dispatch(hideLoading());
                });
        }
    };
    React.useEffect(() => {
        const renameOfferType = () => {
            switch(product.tip_oferta){
                case 'flat':
                    setOfferType('Discount Procentual');
                    break;
                case 'cumpara_x_primesti_y':
                    setOfferType(`${product.prag_oferta[0].prag_cantitate} + ${product.prag_oferta[0].valoare_oferta} gratis`);
                    break;
                case 'cumpara_x_primesti_y_procentaj':
                    setOfferType(`${product.prag_oferta[0].valoare_oferta}% discount la minim ${product.prag_oferta[0].prag_cantitate} bucati`);
                    break;
                default:
                    setOfferType('');
            }
        };
        renameOfferType();
    }, [product.tip_oferta]);
    const handleQuantityChange = (newQuantity) => {
        setQuantity(newQuantity);
    };

    return (
        <div className={styles.productContainer}>
            {product?.tip_oferta !== 'none' && (
                <div className={styles.banderoleContainer}>
                    <div className={styles.banderole}>
                        Oferta %
                    </div>
                </div>
            )}
            {
                product?.tip_oferta !== 'none' ?
                    <div className={styles.offerType}>
                        {offerType}
                    </div>
                    : null
            }
            <div className={styles.productImageContainer}>
                <Link to={`/produs/${product?.id}`} className={`${styles.productImageContainer}`}>
                    <img className={styles.productPicture} src={`${appUrl}${product?.imagine?.url}`} alt={product?.nume_produs} />
                </Link>
            </div>
            <Link to={`/produs/${product?.id}`} className={styles.productTitleLink}>
                <div className={`${styles.productTitle} ${styles.productPadding}`}>
                    {/* {product?.nume_produs}, {product?.mod_comercializare}
                    {product?.mod_comercializare === 'bucata' && product?.gramaj ? `, ${product?.gramaj} kg` : ''} */}
                    {product?.nume_produs}
                </div>
            </Link>
            {product?.pret_inainte_de_reducere && (
                <div className={`${styles.productDiscountContainer} ${styles.productPadding}`}>
                    <div className={styles.productInitialPrice}>{product?.pret_inainte_de_reducere} €</div>
                    <div className={styles.productReducedPercentage}>
                        {/* Discount percentage calculation if needed */}
                    </div>
                </div>
            )}
            <div className={styles.insideProductContainer}>
                <div className={styles.productPadding}>
                    Cod: {product?.cod_produs_intern}
                </div>
                <div className={`${styles.productPrice} ${styles.productPadding}`}>
                    
                    {price.toFixed(2).replace('.', ',')} €
                </div>
                <div className={styles.productPadding}>
                    {priceWithoutVAT.toFixed(2).replace('.', ',')} € + {product?.tva}% VAT
                </div>
            </div>
            <div style={{ width: '100%', marginTop: 'auto' }}>
                <div>
                    <QuantitySelector
                        productId={product?.id}
                        quantity={quantity}
                        handleQuantityChange={handleQuantityChange}
                        context="productCard"
                    />
                </div>
                <button onClick={() => { handleAddToBasket(); setShowAddedToCart(true) }} className={styles.productBasket}>
                    <AddedToCartModal
                        isVisible={showAddedToCart}
                        onHide={() => setShowAddedToCart(false)}
                    />
                </button>
            </div>
        </div>
    );
};

export default Product;